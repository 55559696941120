// Safari 3.0+ "[object HTMLElementConstructor]" 
var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
})(!window['safari'] || safari.pushNotification);

var dz = {};

var app = {
    MAX_WIDTH: 713,
    MAX_HEIGHT: 905,
    ASPECT: 1.27,
    images: {},

    setupImages: function (imgArr) {
        var _this = this;
        $.each(imgArr, function (i, item) {
            _this.toDataUrl(item[1], function (base64Img) {
                _this.images[item[0]] = base64Img;
            },item[2]);
        });
    },

    toDataUrl: function (src, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = this.height;
            canvas.width = this.width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    },

    setupDropzone: function () {
        initDropzone();
    },

    resizeDropZone: function () {
        $('#talentia-dropzone').height(app.ASPECT * $('#talentia-dropzone').width());
    },

    rotateBase64Image: function (base64data, orientation_rot) {
        console.log("Rotate:" + orientation_rot);
        var image = new Image();
        image.src = base64data;

        var w = 0;
        var h = 0;

        var canvas = document.createElement("canvas");
        if (orientation_rot === 0 || orientation_rot === 180) {
            canvas.width = w = image.width;
            canvas.height = h = image.height;
        } else {
            // swap
            canvas.width = w = image.height;
            canvas.height = h = image.width;
        }


        var context = canvas.getContext("2d");

        image.onload = function () {

            context.save();
            // move to the middle of where we want to draw our image
            context.translate(w / 2, h / 2);
            // rotate around that point, converting our 
            // angle from degrees to radians 
            context.rotate(orientation_rot * Math.PI / 180);
            // draw it up and to the left by half the width
            // and height of the image 
            context.drawImage(image, -(image.width / 2), -(image.height / 2));
            // and restore the co-ords to how they were when we began
            context.restore();
            app.resizeRotated(canvas.toDataURL('image/jpeg'), w, h);
        };
    },

    resizeRotated: function (base64data) {
        var img = new Image();
        img.src = base64data;

        img.addEventListener("load", function () {
            var sw, sh; //source width, source height
            sw = img.width;
            sh = img.height;
            console.log("Source width:" + sw);
            console.log("Source height:" + sh);
            console.log("Destination width:" + app.MAX_WIDTH);
            console.log("Destination height:" + app.MAX_HEIGHT);

            if (sw > sh) {
                console.log("landscape");
                app.finalize(img, (sw - sw / app.ASPECT), 0, sh * (1 / app.ASPECT), sh);
            } else if (sh > sw) {
                console.log("portrait");
                app.finalize(img, 0, (sh - sw) / 2, sw, sw * app.ASPECT);
            } else {
				console.log("square");
                app.finalize(img, (sw - sw / app.ASPECT) / app.ASPECT, 0, sh * (1 / app.ASPECT), sh);
            }
        });
    },

    finalize: function (img, cropX, cropY, cropWidth, cropHeight) {
        console.log("cropX, cropY, cropWidth, cropHeight");
        console.log(cropX, cropY, cropWidth, cropHeight);
        // create a temporary canvas sized to the cropped size
        var canvas1 = document.createElement('canvas');
        var ctx1 = canvas1.getContext('2d');
        canvas1.width = app.MAX_WIDTH;
        canvas1.height = app.MAX_HEIGHT;
        // use the extended from of drawImage to draw the
        // cropped area to the temp canvas
        ctx1.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, app.MAX_WIDTH, app.MAX_HEIGHT);

        setTimeout(function () {
            var resizedImage = new Image();
            resizedImage.src = canvas1.toDataURL('image/jpeg'); //skaalattu ja cropattu kuva

            resizedImage.onload = function () {
                $('#talentia-dropzone').hide();
                app.images.profile = canvas1.toDataURL("image/jpeg");
                $('#form-right-wrap').append('<img id="dropzone-profile-image" class=".dropzone-profile-image" style="width: 100%; height: auto;" src="' + canvas1.toDataURL("image/jpeg") + '" />');
				setTimeout(function() {
					$("#dropzone-profile-image").hover(
					  function() {
						$('#image-delete').show();
					  }, function() {
						$('#image-delete').hide();
					  }
					);
				},1);
				
                $('#loader').hide();
            };
        }, 100);
    }

};

function initDropzone() {
    if ($('#dropzone-profile-image').length) {
		$( "#dropzone-profile-image").off("mouseenter mouseleave").remove();
		$('#image-delete').hide();
        $('.dz-preview, .dz-message, .dz-details, dz-progress').show();
        $('#talentia-dropzone').show();
        dz.destroy();        
    }
    ;
    setTimeout(function () {
        dz = new Dropzone("#talentia-dropzone", {
            //paramName: "file", // The name that will be used to transfer the file
            maxFilesize: 16, // MB
            uploadMultiple: false,
            thumbnailWidth: app.MAX_WIDTH,
            thumbnailHeight: app.MAX_HEIGHT,
            maxFiles: 1,
            autoQueue: false,
            autoProcessQueue: false,
            createImageThumbnails: false,
            acceptedFiles: "image/*",
            clickable: true,
            maxfilesexceeded: function (file) {
                this.removeAllFiles();
                this.addFile(file);
            },
            init: function () {
                this.on("addedfile", function (origFile) {
                    $('#loader').show();
                    $('.dz-preview, .dz-message, .dz-details, dz-progress').hide();

                    var reader = new FileReader();
                    var orientation = 0;
                    var orientation_rot = 0;
                    var srcFile = origFile;
                    loadImage.parseMetaData(srcFile, function (data) {
                        if (data.exif) {
                            orientation = data.exif.get('Orientation');
                        } else {
                        }
                    });
                    reader.addEventListener("load", function (event) {

                        var origImg = new Image();
                        origImg.src = event.target.result;

                        origImg.addEventListener("load", function (event) {

                            switch (parseInt(orientation)) {
                                case 3:
                                    orientation_rot = 180;
                                    break;
                                case 6:
                                    orientation_rot = 90;
                                    break;
                                case 8:
                                    orientation_rot = -90;
                                    break;

                                case 4:
                                    orientation_rot = 180;
                                    break;
                                case 5:
                                    orientation_rot = 90;
                                    break;
                                case 7:
                                    orientation_rot = -90;
                                    break;
                            }
                            app.rotateBase64Image(this.src, orientation_rot);
                        });
                    });
                    reader.readAsDataURL(origFile);
                });
            }
        });
    }, 5);

}




$(function () {
    //kuvat
    var imgArr = [
        ['logo', '/build/img/Talentia-tunnus-neg.jpg','image/jpeg'],
        ['sosiaalialalla', '/build/img/sosiaalialalla.jpg','image/jpeg']
    ];
    app.setupImages(imgArr);
    app.setupDropzone();


    //pitää dropzonen kuvasuhteen
    $(window).resize(function () {
        app.resizeDropZone();
    });
    app.resizeDropZone();


    //kuvaustekstin tyhjennys _yhden_ kerran
    $('#person-text').one('click', function () {
        $(this).empty();
    });


    $('body').on('click', '#dropzone-profile-image', function () {
        app.setupDropzone();
    });


    /* PDF GENERATION */
    $('#generate-pdf').click(function () {



        var doc = new jsPDF('p', 'mm', [297, 210]);
        // doc.addFont('Open Sans', 'Open Sans', 'normal');


        //harmaa pohja
        doc.setDrawColor(0);
        doc.setFillColor(87, 87, 86);
        doc.roundedRect(8, 14, 194, 267, 8, 8, 'F');

        //kuvan pohja (tarvitaanko?)   
        //90 x 115mm, kuvasuhde 1:1.27, tulostuskoko @200dpi ~713px x 905px 
        doc.setFillColor(218, 218, 218);
        doc.roundedRect(105, 23, 90, 115, 4, 4, 'F');

        //tumma oranssi laatikko
        doc.setFillColor(251, 191, 0);
        doc.roundedRect(14, 179, 182, 93.5, 4, 4, 'F');

        //tekstilaatikot
        doc.setFillColor(252, 213, 87);
        doc.rect(18.5, 184, 172.8, 12.6, 'F');
        doc.rect(18.5, 198.9, 172.8, 39, 'F');
        doc.rect(58.1, 241, 42.7, 7.2, 'F');
        doc.rect(115.4, 241, 9.0, 7.5, 'F');
        doc.rect(129.6, 241, 9.0, 7.5, 'F');
        doc.rect(146.6, 241, 11.7, 7.5, 'F');
        doc.rect(174.1, 241, 17.0, 7.5, 'F');

        //fontien yleisasetukset
        doc.setFont('Arial');

        //valkoiset isot tekstit (nimi + otsikko)
        doc.setFontType('normal');
        doc.setFontSize(31);
        doc.setTextColor(255, 255, 255);

        //otsikko
        doc.text(38.5, 150.5, 'Anna äänesi talentialaiselle')
        doc.setFontType('bold');
        doc.text(22.3, 194, $('input.person-name').val());

        //alaotsikko valkoinen rivi
        doc.setFontType('normal');
        doc.setFontSize(21);
        doc.text(65.5, 168.1, '– ja tietää, mitä sinä teet.');

        //alaotsikko oranssi rivi
        doc.setTextColor(251, 191, 0);
        doc.text(27, 159.8, 'On hyvä, että luottamusmiehesi tietää, mitä tekee');

        //tekstilaatikon sisältö
        doc.setFontType('normal');
        doc.setFontSize(11);
        doc.setTextColor(87, 87, 86);
        var lines = doc.splitTextToSize($('textarea.person-text').val(), 167);
        doc.text(21.6, 205, lines);

        //paikka ja ajankohta
        doc.setFontSize(12);
        doc.text(59.30, 246, $('#input-location').val());
        doc.text(116.3, 246, $('#input-day').val());
        doc.text(130.6, 246, $('#input-month').val());
        doc.text(147.6, 246, $('#input-year').val());
        doc.text(175.8, 246, $('#input-time').val());


        //alaosan teksti
        var lines2 = doc.splitTextToSize($('p.bottom-text').text(), 167);
        doc.text(21.6, 254, lines2);

        //
        doc.setFontType('bold');
        doc.setFontSize(13);
        doc.text(21, 246.3, 'Äänestyspaikka:');
        doc.text(103.1, 246.3, 'Pvm:');
        doc.text(125.9, 246.3, '.');
        doc.text(139.4, 246.3, '.20');
        doc.text(161.9, 246.3, 'kello:');

        //logo
        doc.addImage(app.images.logo, 'JPEG', 14, 23, 50, 10);

        //sosiaalialalla
        doc.addImage(app.images.sosiaalialalla, 'JPEG', 14, 40, 85, 85);

        doc.addImage(app.images.profile, 'JPEG', 105, 23, 90, 115);


        //tekstit

		var namestr = "Talentia - " +$('input.person-name').val().replace(/[^A-Za-z0-9]/g, '');

        setTimeout(function () {
            if (isSafari) {
                doc.output('dataurl');
            } else {
                doc.output('save', namestr+'.pdf');
            }

        }, 1000);

    });
});